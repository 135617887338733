import store from '@/store';
import { keep2Decimal, keep4Decimal } from '@assets/js/regExUtil';

export let tableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  {
    label: '收票日期',
    prop: 'pinv_recdate',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: false,
    labelWidth: '140px',
    subItem: { required: false, disabled: row => row.disabled, maxLength: 10, type: 'date' }
  },
  {
    label: '发票日期',
    prop: 'pinv_actu_date',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: false,
    labelWidth: '140px',
    subItem: { required: false, disabled: row => row.disabled, maxLength: 10, type: 'date' }
  },
  {
    label: '供应商',
    prop: 'supp_id',
    itemType: 'select',
    input: true,
    sortable: false,
    widthAuto: false,
    labelWidth: '120px',
    options: [],
    filterable: true,
    needOtherColumn: true,
    formatter: val => val.supp_abbr,
    subItem: { required: false, disabled: row => row.disabled, maxLength: 10, type: 'select', options: [], filterable: true }
  },
  {
    label: '品名',
    prop: 'prod_partno',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    labelWidth: '140px',
    subItem: { required: false, disabled: row => row.disabled, type: 'input' }
  },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'select',
    input: false,
    sortable: false,
    widthAuto: false,
    options: [],
    labelWidth: '120px',
    subItem: { required: false, disabled: row => row.disabled, maxLength: 10, type: 'select', options: [] }
  },
  {
    label: '实际收票数量',
    prop: 'pinv_actu_num',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      maxLength: 13,
      type: 'input',
      input: (val, row) => (row.pinv_actu_num = keep4Decimal(val))
    }
  },
  {
    label: '税额',
    prop: 'prod_refundtax',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    labelWidth: '120px',
    subItem: {
      required: false,
      disabled: row => row.disabled,
      maxLength: 15,
      type: 'input',
      input: (val, row) => (row.prod_refundtax = keep4Decimal(val))
    }
  },
  {
    label: '实际不含税金额',
    prop: 'pinv_part_no_subtotal',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      maxLength: 10,
      type: 'input',
      input: (val, row) => (row.pinv_part_no_subtotal = keep2Decimal(val))
    }
  },
  {
    label: '实际收票金额',
    prop: 'pinv_actu_subtotal',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      maxLength: 13,
      type: 'input',
      input: (val, row) => (row.pinv_actu_subtotal = keep2Decimal(val))
    }
  },
  {
    label: '实际收票单价',
    prop: 'pinv_actu_price',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    subItem: {
      required: false,
      disabled: row => row.disabled,
      maxLength: 15,
      type: 'input',
      input: (val, row) => (row.pinv_actu_price = keep4Decimal(val))
    }
  },
  {
    label: '发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    labelWidth: '120px',
    subItem: { required: false, disabled: row => row.disabled, maxLength: 12, type: 'input' }
  },
  store.state.stff_name,
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    input: false,
    widthAuto: false,
    labelWidth: '160px',
    fixed: 'right'
  }
];
