<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="addRow()" icon="el-icon-plus">新增</el-button>
        <el-button type="danger" size="small" @click="delRow()" icon="el-icon-delete">删除</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
        <el-button type="warning" size="small" class="vd_export" icon="el-icon-document-copy" @click="copyAndEdit()">复制并编辑</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'actual_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @selection-change="handleSelectionChange"
        @getTableData="getTableData"
      >
        <template v-slot:operation="scope">
          <el-button v-if="scope.row.disabled" type="success" size="mini" @click="scope.row.disabled = false">编辑</el-button>
          <el-button v-else type="primary" size="mini" @click="saveRow(scope.row)">保存</el-button>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/FinanceManagement/ActualManage/actual';
import { actualAPI } from '@api/modules/actual';
import { getNoCatch } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { optnAPI } from '@api/modules/optn';
import { getUnit } from '@api/public';

export default {
  name: 'actualList',
  components: { SearchTable },
  data() {
    return {
      dateRange: [],
      tableData: [],
      tableMap: new Map(),
      tableProperties: tableProperties,
      loadFlag: false,
      totalPage: 0,
      multiSelection: []
    };
  },
  mounted() {
    this.getTableData();
    this.getSupp();
    this.getUnit();
  },
  methods: {
    refresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    async getTableData() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      // let [startTime, endTime] = this.dateRange;
      // searchForm.start_time = startTime?.getTime();
      // searchForm.end_time = endTime?.getTime();
      await actualAPI.get_actual(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    addRow() {
      this.tableData.push({});
    },
    delRow() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let ids = Array.from(this.multiSelection, ({ actual_id }) => actual_id).join(',');
      actualAPI.delete_actual_ids({ Ids: ids }).then(({ data }) => {
        this.$refs.multiTable.$refs.multiTable.clearSelection();
        this.$message.success('删除成功!');
        this.getTableData();
      });
    },
    getSupp() {
      getNoCatch(suppAPI.getSuppsV1).then(({ data }) => {
        this.suppList = Array.from([...data], ({ supp_id, supp_abbr }) => {
          return { value: supp_id, label: supp_abbr };
        });
        this.tableProperties[4].options = this.suppList;
        this.tableProperties[4].subItem.options = this.suppList;
      });
    },
    saveRow(row) {
      actualAPI.add_actual(row).then(({ data }) => {
        actualAPI.get_actual_id({ Id: data }).then(({ data: subData }) => {
          this.$set(this.tableData, row.index, subData);
          this.$message.success('保存成功!');
        });
      });
    },
    async getUnit() {
      //改为只取中文单位
      let unitList = await getUnit('select', 'param1');
      this.tableProperties[6].options = unitList;
      this.tableProperties[6].subItem.options = unitList;
    },
    copyAndEdit() {
      if (this.multiSelection.length !== 1) return this.$message.warning('请选择一条数据!');
      actualAPI.copy_actual({ Id: this.multiSelection[0].actual_id }).then(({ data: copyId }) => {
        this.copyEnd(copyId);
      });
    },
    async copyEnd(copyId) {
      await this.getTableData();
      let findIndex = this.tableData.findIndex(x => x.actual_id === copyId);
      this.$set(this.tableData[findIndex], 'disabled', false);
      this.$refs.multiTable.$refs.multiTable.clearSelection();
    }
  }
};
</script>

<style scoped></style>
